import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { formatSeconds } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useGenerateSystemDesignScoringItemPropsFactory } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/privateHelper";
import { useGenerateCheatDetectionSectionProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateCheatDetectionSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateQuestionDetailProps";
import SystemDesignPlaybackContainer from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/widget/SystemDesignPlayback/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";
import { useGenerateFollowUpQuestionsSectionProps } from "./useGenerateFollowUpQuestionsSectionProps";

export type GenerateSystemDesignTestReportPropsArgs = {
  featureKind: "test" | "exam";
  entityId: number;
  companyId: number;
  uniqueKey: SystemDesignTestReport.UniqueKey;
  canShowCheatDetectionSection: boolean;
  showUsedHintSection: boolean;
};

export const useGenerateProps = (args: GenerateSystemDesignTestReportPropsArgs): Widget.SystemDesignTestReportProps => {
  const client = getGraphqlClient();
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const currentSubmission = hooks.useCurrentSubmission();
  const aggregatedScoringItemMap = hooks.useAggregatedScoringItemMap();
  const numOfRun = hooks.useNumOfRun();
  const numOfUsedHint = hooks.useNumOfUsedHint();
  const statistics = hooks.useStatistics();
  const appealMessage = hooks.useAppealMessage();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const matchingRole = Role.useMatchingRole();
  const convert = useGenerateSystemDesignScoringItemPropsFactory({ entityId: args.entityId, showAnswer: true });
  const submissionId = hooks.useCurrentSelectedSubmissionId();
  const rankEvaluation = hooks.useRankEvaluation();
  const [mode, setMode] = React.useState<"PLAYBACK" | "SUBMIT_RESULT">("SUBMIT_RESULT");
  const [reevaluateStatus, setReevaluateStatus] = React.useState<"READY" | "PENDING">("READY");
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();
  const followUpQuestionsSectionProps = useGenerateFollowUpQuestionsSectionProps({ entityId: args.entityId });
  const header = useGenerateHeaderProps({ entityId: args.entityId });
  const aggScoringItemMap = hooks.useCalculatedScoringItemMap();
  const scoreBoard = useGenerateEntityScoreBoardProps({ entityId: args.entityId, showingTargets: ["SCORE", "RANK"] });
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: true,
    showArchivedMark: true,
  });
  const cheatDetectionSection = useGenerateCheatDetectionSectionProps({
    ...args,
    systemDesignId: args.entityId,
    canShowStatistic: isAvailableFeature("test.statics.read"),
  });

  const isBest = React.useMemo(() => {
    if (currentSubmission) {
      return currentSubmission.isBest;
    }
    return false;
  }, [currentSubmission]);

  return {
    header: header,
    scoreBoard: scoreBoard,
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) && (
      <TotalScoreRankVisualizerInitialContainer
        uniqueKey={args.uniqueKey}
        score={currentSubmission?.totalScore ?? 0}
        rankEvaluation={rankEvaluation}
        enableRank
      />
    ),
    playbackSection: {
      playbackModeSwitcher: isAvailableFeature(`${args.featureKind}.playback.read`)
        ? {
            enableMode: ["SUBMIT_RESULT", "PLAYBACK"],
            onChangeMode: mode => {
              if (mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
                setMode(mode);
              }
            },
          }
        : undefined,
      ContentsViewer: <SystemDesignPlaybackContainer entityId={args.entityId} mode={mode} />,
      appealMessageArea: !!appealMessage && {
        body: appealMessage,
      },
    },
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(currentSubmission?.totalElapsedTimeSeconds ?? 0, lang),
        icon: {
          key: (currentSubmission?.totalElapsedTimeSeconds ?? 0) <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
      },
      runNum: {
        value: `${numOfRun} ${t("回")}`,
        icon: {
          key: numOfRun <= statistics.numOfRunAvg ? "CHECK" : "WARNING",
          title: `${t("平均実行回数")} ${statistics.numOfRunAvg}`,
        },
      },
      usedHint: args.showUsedHintSection
        ? {
            value: `${numOfUsedHint} ${t("個")}`,
            icon: {
              key: numOfUsedHint <= statistics.numOfHintAvg ? "CHECK" : "WARNING",
              title: `${t("平均使用ヒント数")} ${statistics.numOfHintAvg}`,
            },
          }
        : undefined,
      canShowTooltip: isAvailableFeature(`${args.featureKind}.statics.read`),
    },
    cheatDetectionSection: args.canShowCheatDetectionSection ? cheatDetectionSection : undefined,
    editableScoreSection: {
      onSubmit: React.useCallback(
        fields => {
          setReevaluateStatus("PENDING");
          client
            .ReevaluateSystemDesign({
              systemDesignId: args.entityId,
              submissionId: submissionId,
              evaluationSources: fields.scoringItems.map(item => {
                return {
                  scoringItemId: item.id,
                  isPassed: item.isPassed,
                };
              }),
            })
            .then(res => {
              if (res.reevaluateSystemDesign.submission) {
                SystemDesignTestReport.reevaluate(args.entityId, res.reevaluateSystemDesign.submission.systemDesign);
              }
              /**
               * Clear the cache before revaluation in order to obtain the state after revaluation.
               */
              TotalScoreRankVisualizer.clearQueryResultCache(args.uniqueKey);
              /**
               * The cache is cleared in the previous section, so the latest information is retrieved.
               */
              TotalScoreRankVisualizer.refreshQuery(args.uniqueKey);
              Snackbar.notify({
                severity: "success",
                message: t("採点結果の編集に成功しました。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("採点結果の編集に失敗しました。しばらくしてから再度お試し頂くか、運営までお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              setReevaluateStatus("READY");
            });
        },
        [args.entityId, args.uniqueKey, client, submissionId, t],
      ),
      formActionButtonGroup: {
        cancelButton: {
          loading: reevaluateStatus === "PENDING",
        },
        saveButton: {
          loading: reevaluateStatus === "PENDING",
        },
        editButton: {
          disabled: !isBest || !matchingRole.ADMIN_OR_MANAGER.matched,
          title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
        },
      },
      defaultValues: {
        scoringItems: aggregatedScoringItemMap.all.items.map(item => {
          return {
            id: item.systemDesignScoringItemId,
            isPassed: item.isPassed,
          };
        }),
      },
      availability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.AVAILABILITY_SECTION,
        titleWithScoreBar: {
          title: t("可用性"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "secondary",
        },
        items: aggregatedScoringItemMap.availability.items.map(convert),
      },
      scalability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.SCALABILITY_SECTION,
        titleWithScoreBar: {
          title: t("スケーラビリティ"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "info",
        },
        items: aggregatedScoringItemMap.scalability.items.map(convert),
      },
      consistency: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.CONSISTENCY_SECTION,
        titleWithScoreBar: {
          title: t("一貫性"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "warning",
        },
        items: aggregatedScoringItemMap.consistency.items.map(convert),
      },
    },
    questionDetail: questionDetail,
    followUpQuestionsSection:
      enabledSinSClassPhase1 && isAvailableFeature("interview.create") && followUpQuestionsSectionProps.categorySections.length > 0
        ? followUpQuestionsSectionProps
        : undefined,
  };
};
