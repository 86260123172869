import { App, Auth } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { Fields, RemoteInterviewResourceEditor } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import RemoteInterviewResourceEditorMiddleContainer, { RemoteInterviewResourceEditorMiddleContainerProps } from "./MiddleContainer";
import * as PrivateHelper from "./privateHelper";

export type RemoteInterviewResourceEditorConditionalFetchContainerProps = RemoteInterviewResourceEditorMiddleContainerProps & {
  spotId: string;
  isSubmitting: boolean;
};

const RemoteInterviewResourceEditorConditionalFetchContainer: React.FC<RemoteInterviewResourceEditorConditionalFetchContainerProps> = props => {
  const { t } = useTranslation();
  const appStatus = App.useStatus();
  const currentUid = Auth.useCurrentUid();
  const { spotId, ...rest } = props;
  const [result, refresh] = Graphql.useGetSpotForRemoteInterviewResourceEditorQuery({
    variables: {
      spotId: spotId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.spot.viewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "EmployeeGroup": {
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        }
        case "User": {
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: viewer.uid === currentUid,
            },
          });
        }
        case undefined:
          return all;
        default:
          throw new Error(`Invalid type: ${viewer satisfies never}`);
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  const inheritedEntities = React.useMemo((): RemoteInterviewResourceEditor.InheritedEntitySchema[] => {
    return (result.data?.spot.issuedEntities ?? []).reduce<RemoteInterviewResourceEditor.InheritedEntitySchema[]>((all, issuedEntity) => {
      switch (issuedEntity.__typename) {
        case "SpotIssuedChallengeEntity":
          if (!issuedEntity.entity.challengeQuestion?.key) break;
          return all.concat({
            entityType: "CHALLENGE",
            entityId: issuedEntity.entity.entityId,
            questionKey: issuedEntity.entity.challengeQuestion.key,
          });
        case "SpotIssuedSystemDesignEntity":
          if (!issuedEntity.entity.question?.key) break;
          return all.concat({
            entityType: "SYSTEM_DESIGN",
            entityId: issuedEntity.entity.entityId,
            questionKey: issuedEntity.entity.question.key,
          });
        case "SpotIssuedProjectEntity":
        case "SpotIssuedQuizEntity":
          break;
        case undefined:
          break;
        default:
          throw new Error(`Unknown type: ${issuedEntity satisfies never}`);
      }
      return all;
    }, []);
  }, [result.data]);

  const testDetailLink = React.useMemo(() => {
    return generateCurrentOriginUrl("/e/screenings/tests/:id/detail", { pathParams: { id: spotId } });
  }, [spotId]);

  React.useEffect(() => {
    if (result.data?.spot) {
      RemoteInterviewResourceEditorStore.initializeQuestionKeyInheritedEntitySourceMap(result.data.spot.issuedEntities);

      const questions = result.data.spot.issuedEntities.reduce<QuestionSelectFieldForResourceEditor.Question[]>((all, issuedEntity) => {
        switch (issuedEntity.__typename) {
          case "SpotIssuedChallengeEntity":
            if (issuedEntity.entity.challengeQuestion) {
              all.push(issuedEntity.entity.challengeQuestion);
            }
            break;
          case "SpotIssuedSystemDesignEntity":
            if (issuedEntity.entity.question) {
              all.push(issuedEntity.entity.question);
            }
            break;
          case "SpotIssuedProjectEntity":
          case "SpotIssuedQuizEntity":
          case undefined:
            break;
          default:
            throw new Error(`Invalid EntityTrack: ${issuedEntity satisfies never}`);
        }
        return all;
      }, []);
      QuestionSelectFieldForResourceEditor.setQuestion(questions);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }
  const memo = result.data?.spot?.memo;

  const containerProps: RemoteInterviewResourceEditorMiddleContainerProps = {
    ...rest,
    /** ConditionalFetchContainerは引き継ぎ情報を取得した後のみのため確認画面から始める **/
    initialStepName: "CONFIRM",
    defaultValues: {
      remoteQuestionSetup: {
        name: `${result.data?.spot?.candidateName}`,
        candidateName: result.data?.spot?.candidateName || "",
        entityTracks: PrivateHelper.convertEntityTracksValidatorFromGraphqlIssuedEntities(result.data?.spot.issuedEntities ?? []),
        inheritedEntities: inheritedEntities,
      },
      remoteReportSetup: {
        memo: `${memo ? `${memo}\n` : ""}${t("【")}${t("引き継ぎ元のコーディングテストの結果")}${t("】")}\n${testDetailLink}`,
        isPublic: result.data?.spot?.isPublic ?? true,
        editorUid: currentUid,
        viewers: viewers,
        tags: result.data?.spot?.tags?.map(t => ({ value: { name: t.name } })) || [],
      },
    },
  };

  return <RemoteInterviewResourceEditorMiddleContainer {...containerProps} />;
};

RemoteInterviewResourceEditorConditionalFetchContainer.displayName = "RemoteInterviewResourceEditorConditionalFetchContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorConditionalFetchContainer, {});
