import { UNSUPPORTED_READABILITY_RUNTIMES } from "@hireroo/app-definition/challenge";
import { resolveTestCaseLanguage, TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { safeJsonParse } from "@hireroo/app-helper/parser";
import { Payment } from "@hireroo/app-store/essential/employee";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";

import { useGenerateCheatDetectionSectionProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateCheatDetectionSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateHeaderProps";
import { useGeneratePerformanceSection } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGeneratePerformanceSection";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateQuestionDetailProps";
import { useGenerateReadabilitySection } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateReadabilitySection";
import ChallengePlaybackFetchContainer, { ChallengePlaybackFetchContainerProps } from "../../shared/ChallengePlayback/FetchContainer";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";
import UpsellLockButtonDialogContainer from "../UpsellLockButtonDialog/Container";
import { useGenerateFeedbackSuspiciousDegreeDialog } from "./useGenerateFeedbackSuspiciousDegreeDialog";
import { useGenerateFollowUpQuestionsSectionProps } from "./useGenerateFollowUpQuestionsSectionProps";
import ChallengeTestReportSuspiciousDegreeMessageForQuestion, {
  ChallengeTestReportSuspiciousDegreeMessageForQuestionProps,
} from "./widget/SuspiciousDegreeMessageForQuestion/Container";

export type GenerateChallengeTestReportPropsArgs = {
  featureKind: "test" | "exam";
  uniqueKey: ChallengeTestReport.UniqueKey;
  challengeId: number;
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  canShowFeedbackSuspiciousDegree: boolean;
  showUsedHintSection: boolean;
};

type AnswerDetailSection = Widget.ChallengeTestReportProps["answerDetailSection"];
type TestCaseResult = Exclude<Widget.ChallengeTestReportProps["testCaseSection"], undefined>["testCaseResults"][0];
type AnswerDetailSectionUsedHint = Exclude<Widget.ChallengeTestReportProps["answerDetailSection"], undefined>["usedHint"];

type ComplexityStatus = "OPTIMAL" | "NON_OPTIMAL";

export const useGenerateProps = (args: GenerateChallengeTestReportPropsArgs): Widget.ChallengeTestReportProps => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const challenge = challengeHooks.useChallenge();
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();

  const isAvailableFeature = Payment.useIsAvailableFeature();
  const statistics = challengeHooks.useStatistics();
  const optimalAnswer = challengeHooks.useOptimalAnswer();

  const submissionMap = challengeHooks.useSubmissionMap();
  const header = useGenerateHeaderProps({ challengeId: args.challengeId, showDetail: true });
  const correctnessTestCases = AlgorithmTestCaseForm.useCorrectnessTestCasesFromJsonDangerous();
  const performanceSection = useGeneratePerformanceSection({
    challengeId: args.challengeId,
    submissionId,
    showScore: true,
  });
  const readabilitySection = useGenerateReadabilitySection({
    challengeId: args.challengeId,
    submissionId,
    showScore: true,
  });
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();
  const followUpQuestionsSectionProps = useGenerateFollowUpQuestionsSectionProps({ challengeId: args.challengeId });
  const submission = submissionMap[submissionId];

  const suspiciousDegreeMessageForQuestion = React.useMemo((): ChallengeTestReportSuspiciousDegreeMessageForQuestionProps | undefined => {
    if (!args.canShowFeedbackSuspiciousDegree) {
      return undefined;
    }
    if (challenge.suspiciousInference === null) {
      return undefined;
    }
    return {
      challengeId: args.challengeId,
      suspiciousInference: challenge.suspiciousInference,
    };
  }, [args.canShowFeedbackSuspiciousDegree, args.challengeId, challenge.suspiciousInference]);

  const SuspiciousDegreeMessageForQuestion = React.useMemo(() => {
    if (!suspiciousDegreeMessageForQuestion) {
      return null;
    }
    return <ChallengeTestReportSuspiciousDegreeMessageForQuestion {...suspiciousDegreeMessageForQuestion} />;
  }, [suspiciousDegreeMessageForQuestion]);

  const question = challengeHooks.useQuestion();
  const sessionIds = challengeHooks.useChallengeSessionIds(submission.runtime);
  const playbackSection = React.useMemo((): ChallengePlaybackFetchContainerProps => {
    return {
      challengeId: args.challengeId,
      question: question,
      sessionIds: sessionIds,
      submission: submission,
      appealMessage: challenge.appealMessage ?? null,
      playbackAccess: isAvailableFeature(`${args.featureKind}.playback.read`) ? "AVAILABLE" : "LOCKED",
      canShowPasteAndTabStatistics: args.canShowPasteAndTabStatistics,
      canShowCheatDetectionSection: args.canShowCheatDetectionSection,
      canShowStatistic: isAvailableFeature(`${args.featureKind}.statics.read`),
      SuspiciousDegreeMessageForQuestion: SuspiciousDegreeMessageForQuestion,
    };
  }, [
    args.challengeId,
    args.canShowPasteAndTabStatistics,
    args.canShowCheatDetectionSection,
    args.featureKind,
    challenge.appealMessage,
    isAvailableFeature,
    question,
    sessionIds,
    submission,
    SuspiciousDegreeMessageForQuestion,
  ]);

  const PlaybackSection = React.useMemo(() => {
    return <ChallengePlaybackFetchContainer {...playbackSection} />;
  }, [playbackSection]);

  const entityScoreBoard = useGenerateEntityScoreBoardProps({
    challengeId: args.challengeId,
    showingTargets: ["SCORE", "RANK"],
  });
  const questionDetail = useGenerateQuestionDetailProps({
    challengeId: args.challengeId,
    showAnswer: true,
    showArchivedMark: true,
  });
  const cheatDetectionSection = useGenerateCheatDetectionSectionProps({
    ...args,
    canShowStatistic: isAvailableFeature("test.statics.read"),
  });

  const hasReadability = React.useMemo(() => {
    const submission = submissionMap[submissionId];
    if (!submission) return false;
    if (submission?.readabilityTestResult === "") {
      return false;
    } else {
      return !UNSUPPORTED_READABILITY_RUNTIMES.includes(submission.runtime);
    }
  }, [submissionMap, submissionId]);

  const totalScore = React.useMemo((): number => {
    const submission = submissionMap[submissionId];
    return submission.totalScore;
  }, [submissionId, submissionMap]);

  const answerDetailSection = React.useMemo((): AnswerDetailSection => {
    const submission = submissionMap[submissionId];
    const runtimeComplexityTooltipTextMap: Record<ComplexityStatus, string> = {
      OPTIMAL: t("提出された答えは最適解として検出されました。"),
      NON_OPTIMAL: t("提出された答えは非最適解として検出されました。"),
    };
    const isCorrectRuntimeComplexity = optimalAnswer && optimalAnswer.runtimeComplexity === submission.runtimeComplexity;
    const complexityStatus = isCorrectRuntimeComplexity ? "OPTIMAL" : "NON_OPTIMAL";
    const usedHint: AnswerDetailSectionUsedHint = args.showUsedHintSection
      ? {
          value: `${submission.challengeUsedHintCount} ${t("回")}`,
          icon: {
            key: (submission.challengeUsedHintCount ?? 0) <= Math.round(statistics?.numHints?.avg ?? 0) ? "CHECK" : "WARNING",
            title: `${t("平均使用ヒント数")} ${Math.round(statistics?.numHints?.avg ?? 0)}`,
          },
        }
      : undefined;
    return {
      runtime: submission.runtime,
      // runtimeVersionのデフォルト値はlatestであり、問題提出後の評価時にruntimeVersionが保存されるためlatestのハンドリングを行っている。
      // また、databaseの問題は複数バージョンサポートしているわけではないので全部latestが入る。
      runtimeVersion: submission.runtimeVersion === "latest" ? languageMapForDisplay[submission.runtime] : submission.runtimeVersion,
      totalElapsedTime: {
        value: `${Time.formatSeconds(submission?.totalElapsedTimeSeconds ?? 0, lang)}`,
        icon: {
          key: (submission?.totalElapsedTimeSeconds ?? 0) < (statistics?.elapsedTime?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${Time.formatSeconds(statistics?.elapsedTime?.avg ?? 0, lang)}`,
        },
      },
      executeTime: {
        value: `${submission.snapshots.length} ${t("回")}`,
        icon: {
          key: (submission.snapshots.length ?? 0) <= Math.round(statistics?.numSnapshots?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均実行回数")} ${Math.round(statistics?.numSnapshots?.avg ?? 0)}`,
        },
      },
      usedHint: usedHint,
      complexTime: {
        value: submission?.runtimeComplexity !== "" ? submission.runtimeComplexity : t("計測不能"),
        icon: {
          key: isCorrectRuntimeComplexity ? "CHECK" : "WARNING",
          title: optimalAnswer
            ? runtimeComplexityTooltipTextMap[complexityStatus]
            : t(
                "この問題には最適解が登録されていないため、予測計算量が算出できませんでした。問題編集画面より最適解のコードを追加することによって評価可能です。",
              ),
        },
      },
      performance: {
        value: `${Time.getTimeUnitText(submission.avgPerformance, Time.Unit.NANOSECOND)}`,
        icon: {
          key: submission.avgPerformance <= (statistics?.avgPerformance?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均パフォーマンス")} ${Time.getTimeUnitText(statistics?.avgPerformance?.avg ?? 0, Time.Unit.NANOSECOND)}`,
        },
      },
      memory: {
        value: submission?.avgMaxMemory !== undefined ? `${Math.floor(submission.avgMaxMemory)} MB` : "-",
        icon: {
          key: Math.floor(submission.avgMaxMemory) <= Math.floor(statistics?.avgMaxMemory?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均メモリ使用量")} ${Math.floor(statistics?.avgMaxMemory?.avg ?? 0)} MB`,
        },
      },
      canShowTooltip: isAvailableFeature(`${args.featureKind}.statics.read`),
    };
  }, [
    submissionMap,
    submissionId,
    t,
    optimalAnswer,
    lang,
    statistics?.elapsedTime?.avg,
    statistics?.numSnapshots?.avg,
    statistics?.numHints?.avg,
    statistics?.avgPerformance?.avg,
    statistics?.avgMaxMemory?.avg,
    isAvailableFeature,
    args.featureKind,
    args.showUsedHintSection,
  ]);

  const testCaseResults = React.useMemo((): TestCaseResult[] => {
    const parsedResult = correctnessTestCases.safeParse(safeJsonParse(challenge?.challengeQuestion?.correctnessTestCase ?? ""));
    const submission = submissionMap[submissionId];

    const result = AlgorithmTestCaseForm.CorrectnessTestCaseResults.safeParse(safeJsonParse(submission.correctnessTestResult ?? ""));
    if (result.success && parsedResult.success) {
      return result.data.map((testCaseResult, index): TestCaseResult => {
        const correctnessTestCase = parsedResult.data.at(index);

        return {
          id: index.toString(),
          detail: {
            invisible: correctnessTestCase?.is_hidden ?? false,
            title: resolveTestCaseLanguage(testCaseResult, lang, "title") ?? `${t("テストケース")}${index}`,
            status: testCaseResult.is_passed ? "SUCCESS" : "ERROR",
          },
          description: resolveTestCaseLanguage(testCaseResult, lang, "description") ?? t("テストケースの説明がありません。"),
          diff: {
            inputs: correctnessTestCase?.inputs ?? [],
            expected: testCaseResult.expected,
            output: testCaseResult.output,
          },
        };
      });
    }
    return [];
  }, [challenge?.challengeQuestion?.correctnessTestCase, correctnessTestCases, lang, submissionId, submissionMap, t]);

  const feedbackSuspiciousDegreeDialog = useGenerateFeedbackSuspiciousDegreeDialog({
    challengeId: args.challengeId,
    canShowFeedbackSuspiciousDegree: args.canShowFeedbackSuspiciousDegree,
  });

  return {
    header: header,
    entityScoreBoard: entityScoreBoard,
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) ? (
      <TotalScoreRankVisualizerInitialContainer
        uniqueKey={args.uniqueKey}
        score={totalScore}
        rankEvaluation={challenge.rankEvaluation}
        enableRank
      />
    ) : (
      <UpsellLockButtonDialogContainer kind="STATISTICS" />
    ),
    answerDetailSection: answerDetailSection,
    cheatDetectionSection: cheatDetectionSection,
    testCaseSection: {
      score: submissionMap[submissionId]?.coverage ? formatScore(submissionMap[submissionId].coverage) : 0,
      scoreAnswerRateDisplayLabel: {
        numPassed: submissionMap[submissionId]?.numPassed ?? 0,
        numTests: submissionMap[submissionId]?.numTests ?? 0,
      },
      language: lang,
      testCaseResults: testCaseResults,
      targetElementId: TargetElementIdMap.CORRECT_RATE_SECTION,
    },
    performanceSection: performanceSection,
    readabilitySection: hasReadability ? readabilitySection : undefined,
    PlaybackSection: PlaybackSection,
    questionDetail: questionDetail,
    SuspiciousDegreeMessageForQuestion: SuspiciousDegreeMessageForQuestion,
    feedbackSuspiciousDegreeDialog: feedbackSuspiciousDegreeDialog,
    followUpQuestionsSection:
      enabledSinSClassPhase1 && isAvailableFeature("interview.create") && followUpQuestionsSectionProps.categorySections.length > 0
        ? followUpQuestionsSectionProps
        : undefined,
  };
};
