import { proxyMap, proxySet } from "valtio/utils";

import { State } from "./types";

export const createDefaultState = (): Readonly<State> => ({
  activeStep: 0,

  generalSettings: {
    title: "",
    candidateName: "",
    memo: "",
    isPrivate: false,
  },
  employeeIds: [],
  groupIds: [],

  originalQuestions: proxyMap(),
  unselectedQuestionKeys: proxySet(),
  selectedQuestionKeys: proxySet(),

  draftTags: proxySet(),
  tags: proxyMap(),

  remoteItems: proxyMap(),
  remotes: proxyMap(),

  isFetchRemoteLoading: false,
  isCreateRemoteLoading: false,
  isUpdateRemoteLoading: false,
  listQuestionStatus: "READY",

  submitValues: {
    REMOTE_QUESTION_SETUP: {
      name: "",
      candidateName: "",
      entityTracks: [],
    },
    REPORT_SETUP: {
      memo: "",
      isPublic: true,
      editorUid: "",
      viewers: [],
      tags: [],
    },
  },
  questionKeyInheritedEntitySourceMap: proxyMap(),
});
