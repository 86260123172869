import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { RemoteInterviewResourceEditor } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import RemoteInterviewResourceEditorConditionalFetchContainer from "../../../../widget/v2/e/RemoteInterviewResourceEditor/ConditionalFetchContainer";
import RemoteInterviewResourceEditorFetchContainer from "../../../../widget/v2/e/RemoteInterviewResourceEditor/FetchContainer";
import RemoteInterviewResourceEditorMiddleContainer, {
  RemoteInterviewResourceEditorMiddleContainerProps,
} from "../../../../widget/v2/e/RemoteInterviewResourceEditor/MiddleContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GenerateRemoteInterviewResourceEditorPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewResourceEditorPropsArgs): Pages.RemoteInterviewResourceEditorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? null;
  const spotId = searchParams.get("spotId") ?? null;
  const client = getGraphqlClient();
  const companyId = Company.useStrictActiveCompanyId();
  const currentUid = Auth.useCurrentUid();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();

  useTitle(t("インタビュー作成"));

  const remoteInterviewResourceEditorProps: RemoteInterviewResourceEditorMiddleContainerProps = {
    mode: "CREATE",
    loading: status === "PENDING",
    onSubmit: fields => {
      setStatus("PENDING");
      const questionKeyInheritedEntityMap = new Map<string, RemoteInterviewResourceEditor.InheritedEntitySchema>();
      fields.remoteQuestionSetup.inheritedEntities?.forEach(inheritedEntity => {
        questionKeyInheritedEntityMap.set(inheritedEntity.questionKey, inheritedEntity);
      });
      client
        .CreateRemoteForRemoteInterviewsCreate({
          createRemoteInput: {
            companyId: companyId,
            employeeId: currentUid,
            isPublic: fields.remoteReportSetup.isPublic,
            name: fields.remoteQuestionSetup.name,
            candidateName: fields.remoteQuestionSetup.candidateName,
            memo: fields.remoteReportSetup.memo,
            tagNames: fields.remoteReportSetup.tags.map(t => t.value.name),
            entitySources: fields.remoteQuestionSetup.entityTracks.reduce<Graphql.RemoteEntitySourceInput[]>((all, entityTrack) => {
              /** Currently, Remote Interview does not support anything other than FIXED */
              if (entityTrack.type !== "FIXED") {
                return all;
              }
              const entitySource = entityTrack.entitySource;
              const inheritedEntity = questionKeyInheritedEntityMap.get(entitySource.uniqueKey);
              if (enabledSinSClassPhase1 && fields.remoteQuestionSetup.inheritedEntities && inheritedEntity) {
                return all.concat({
                  entityType: "LIVE_CODING",
                  inheritedEntity: {
                    entityType: inheritedEntity.entityType,
                    entityId: inheritedEntity.entityId,
                  },
                  variant: inheritedEntity.entityType === "CHALLENGE" ? "ALGORITHM" : "SYSTEM_DESIGN",
                });
              }
              switch (entitySource.type) {
                case "REMOTE_ALGORITHM":
                  return all.concat({
                    entityType: "LIVE_CODING",
                    algorithmQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                    },
                    variant: "ALGORITHM",
                  });
                case "REMOTE_SYSTEM_DESIGN":
                  return all.concat({
                    entityType: "LIVE_CODING",
                    systemDesignQuestion: {
                      questionId: entitySource.questionId,
                    },
                    variant: "SYSTEM_DESIGN",
                  });
              }
              return all;
            }, []),
            viewerIds: fields.remoteReportSetup.viewers.reduce<Graphql.RemoteViewerIdInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              switch (reviewer.value.type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: "EMPLOYEE",
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: "GROUP",
                    groupId: reviewer.value.groupId,
                  });
                default:
                  break;
              }
              return viewerIds;
            }, []),
          },
        })
        .then(res => {
          Snackbar.notifyWithAction({
            action: {
              message: t("インタビューからインタビューテンプレートを作成する"),
              callback: () => {
                navigate("/e/templates/remote/create", {
                  queryParams: {
                    id: res.createRemote.id,
                  },
                });
              },
            },
            message: t("インタビューの作成が完了しました。"),
            severity: "success",
          });
          navigate("/e/remotes/:id/detail", { pathParams: { id: res.createRemote.id } });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("インタビューの作成に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setStatus("READY");
        });
    },
  };

  return {
    layout: {
      loading: status === "PENDING",
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/remotes"),
            children: t("インタビュー一覧"),
            onClick: () => {
              navigate("/e/remotes");
            },
          },
        ],
        current: t("インタビュー作成"),
      },
    },
    children:
      enabledSinSClassPhase1 && spotId ? (
        <RemoteInterviewResourceEditorConditionalFetchContainer
          {...remoteInterviewResourceEditorProps}
          spotId={spotId}
          isSubmitting={status === "PENDING"}
        />
      ) : id ? (
        <RemoteInterviewResourceEditorFetchContainer {...remoteInterviewResourceEditorProps} remoteId={id} />
      ) : (
        <RemoteInterviewResourceEditorMiddleContainer {...remoteInterviewResourceEditorProps} />
      ),
  };
};
