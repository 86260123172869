import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { RuntimeSelector, RuntimeSelectorProps } from "@hireroo/challenge/react/usecase";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import RemoteInterviewChallengeCodingEditorContainer, {
  RemoteInterviewChallengeCodingEditorContainerProps,
} from "../../../../widget/v2/shared/RemoteInterviewChallengeCodingEditor/Container";
import RemoteInterviewSystemDesignCodingEditorContainer, {
  RemoteInterviewSystemDesignCodingEditorContainerProps,
} from "../../../../widget/v2/shared/RemoteInterviewSystemDesignCodingEditor/Container";

type TabItem = Pages.RemoteInterviewForEmployeeProps["mainContent"]["tab"]["items"][0];

export type GenerateMainContentPropsArgs = {
  collaborativeState: LiveCoding.CollaborativeState;
  collaborativeAction: LiveCoding.CollaborativeAction;
};

export const useGenerateMainContentProps = (args: GenerateMainContentPropsArgs): Pages.RemoteInterviewForEmployeeProps["mainContent"] => {
  const uid = Auth.useCurrentUid();
  const { collaborativeAction, collaborativeState } = args;
  const { t } = useTranslation();
  const sessions = RemotesId.useNormalizedSessions();
  const activeSession = RemotesId.useActiveSession();
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const currentSessionId = RemotesId.useActiveSessionId();
  const liveCodingId = RemotesId.useLiveCodingId();
  const liveCodingTemplateCodes = RemotesId.useLiveCodingTemplateCodes();
  const isLiveCodingTemplateCodesFetched = RemotesId.useLiveCodingTemplateCodeFetched();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const currentUser = Auth.useCurrentUser();
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();

  const defaultValue = React.useMemo((): string => {
    if (
      enabledSinSClassPhase1 &&
      activeSession?.isInherited &&
      activeSession?.questionExtras?.__typename === "LiveCodingAlgorithmQuestionExtras" &&
      activeSession.questionExtras.initialCode
    ) {
      return activeSession.questionExtras.initialCode;
    }
    if (!liveCodingTemplateCodes) {
      return "";
    }
    return liveCodingTemplateCodes[collaborativeState.selectedLanguage as RemotesId.LiveCodingTemplateCodeLanguage] || "";
  }, [
    enabledSinSClassPhase1,
    activeSession?.questionExtras,
    activeSession?.isInherited,
    collaborativeState.selectedLanguage,
    liveCodingTemplateCodes,
  ]);

  const initialFlowChart = React.useMemo((): string => {
    if (
      enabledSinSClassPhase1 &&
      activeSession?.isInherited &&
      activeSession?.questionExtras?.__typename === "LiveCodingSystemDesignQuestionExtras" &&
      activeSession.questionExtras.initialFlowChart
    ) {
      return activeSession.questionExtras.initialFlowChart;
    }
    return "";
  }, [enabledSinSClassPhase1, activeSession?.questionExtras, activeSession?.isInherited]);

  const handleChangeLanguage = React.useCallback<Exclude<RuntimeSelectorProps["onChange"], undefined>>(
    language => {
      collaborativeAction.setSelectedLanguageWrapper(language);
    },
    [collaborativeAction],
  );

  const remoteInterviewChallengeCodingEditorContainerProps: RemoteInterviewChallengeCodingEditorContainerProps = {
    collaborativeState,
    uid: currentUser.uid,
    displayName: currentUser.displayName,
    liveCodingId: liveCodingId,
    defaultValue: defaultValue,
    /**
     * The first user among the participants is allowed to update the initialization
     */
    allowSetInitialValue: collaborativeState.sortedCurrentParticipantUids[0] === uid,
  };

  const getNextActiveSessionId = React.useCallback(
    (closingSessionId: number) => {
      if (typeof currentSessionId === "number" && currentSessionId !== closingSessionId) {
        return currentSessionId;
      }

      const closingSessionIndex = sessions.findIndex(({ liveCodingSessionId }) => liveCodingSessionId === closingSessionId);
      const newSessionIds = sessions.map(({ liveCodingSessionId }) => liveCodingSessionId).filter(sessionId => sessionId !== closingSessionId);
      const neighboringIndex = closingSessionIndex < newSessionIds.length ? closingSessionIndex : closingSessionIndex - 1;
      const nextActiveSessionIndex = Math.max(0, neighboringIndex);

      return newSessionIds[nextActiveSessionIndex] ?? 0;
    },
    [currentSessionId, sessions],
  );

  const remoteInterviewSystemDesignCodingEditorContainerProps: RemoteInterviewSystemDesignCodingEditorContainerProps = {
    collaborativeState,
    collaborativeAction,
    uid: currentUser.uid,
    displayName: currentUser.displayName,
    liveCodingId: liveCodingId,
    initialFlowChart: initialFlowChart,
  };

  const variantComponentMap: Record<Graphql.RemoteQuestionVariant, React.ReactNode> = {
    ALGORITHM:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    DATABASE:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    CLASS:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    SYSTEM_DESIGN: collaborativeState.ready ? (
      <RemoteInterviewSystemDesignCodingEditorContainer {...remoteInterviewSystemDesignCodingEditorContainerProps} />
    ) : (
      <Widget.Loading kind="CENTER_%" color="secondary" />
    ),
    UNKNOWN: null,
  };

  const extraComponentMap: Record<Graphql.RemoteQuestionVariant, React.ReactNode> = {
    ALGORITHM: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    DATABASE: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    CLASS: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    SYSTEM_DESIGN: null,
    UNKNOWN: null,
  };

  // const liveCodingVideoChatPopperProps = useGenerateLiveCodingVideoChatPopperProps();

  return {
    tab: {
      items: sessions.map((session): TabItem => {
        return {
          id: session.liveCodingSessionId.toString(),
          label: {
            title: resolveLanguage(session, lang, "title"),
            deleteDialog: {
              name: resolveLanguage(session, lang, "title"),
              onDelete: controller => {
                controller.setLoading(true);
                client
                  .DeleteLiveCodingSession({
                    input: {
                      sessionId: session.liveCodingSessionId,
                      liveCodingId: session.liveCodingId,
                    },
                  })
                  .then(() => {
                    Snackbar.notify({
                      severity: "success",
                      message: t("セッションを削除しました。"),
                    });
                    controller.onClose();
                    const nextActiveSessionId = getNextActiveSessionId(session.liveCodingSessionId);
                    collaborativeAction.setSelectedSessionWrapper(nextActiveSessionId);
                    collaborativeAction.deleteSession(session.liveCodingSessionId);
                    RemotesId.updateActiveSessionId(nextActiveSessionId);
                    RemotesId.removeSession(session.liveCodingSessionId);
                    RemotesId.QuestionsTableStore.showQuestion(`${session.questionType}-${session.questionId}-${session.questionVersion}`);
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t("セッションの削除に失敗しました。しばらくしてから再度お試しください。"),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .catch(() => {
                    controller.setLoading(false);
                  });
              },
            },
          },
          children: variantComponentMap[session.variant],
          onClick: () => {
            if (currentSessionId) collaborativeAction.setOutSessionWrapper(currentSessionId);
            collaborativeAction.setSelectedSessionWrapper(session.liveCodingSessionId);
          },
        };
      }),
      Extra: extraComponentMap[activeSessionVariant],
      currentTab: (currentSessionId ?? "").toString(),
    },
    // liveCodingVideoChatPopper: liveCodingVideoChatPopperProps,
  };
};
