import { RemoteCreateDialog } from "@hireroo/app-store/widget/e/RemoteCreateDialog";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import ScreeningTestListForRemoteContentContainer from "./widgets/ScreeningTestListForRemoteContent/Container";

export type GenerateRemoteCreateDialogPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteCreateDialogPropsArgs): Widget.RemoteCreateDialogProps => {
  const navigate = useTransitionNavigate();
  const open = RemoteCreateDialog.useOpen();
  const step = RemoteCreateDialog.useStep();
  const selectedSpotId = RemoteCreateDialog.useSelectedSpotId();
  const initialized = RemoteCreateDialog.useInitialized();

  return {
    dialog: {
      open: open,
      onClose: () => {
        RemoteCreateDialog.setOpen(false);
      },
    },
    createOption: {
      createNewButton: {
        onClick: () => {
          navigate("/e/remotes/create");
        },
      },
      selectFromTestButton: {
        onClick: () => {
          RemoteCreateDialog.updateStep("TEST_LIST");
        },
      },
    },
    step: step,
    TestList: <ScreeningTestListForRemoteContentContainer />,
    testListButton: {
      yesButton: {
        onClick: () => {
          if (!selectedSpotId) return;
          navigate("/e/remotes/create", { queryParams: { spotId: selectedSpotId } });
        },
        disabled: selectedSpotId === null || !initialized,
      },
      noButton: {
        onClick: () => {
          RemoteCreateDialog.updateStep("CHOOSE_OPTION");
          RemoteCreateDialog.clearSelectedSpotId();
        },
      },
    },
  };
};
