import { useFollowUpQuestionPriorityMap } from "@hireroo/app-definition/livecoding";
import { useSystemDesignFollowUpQuestionCategoryMap } from "@hireroo/app-definition/systemDesign";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { generateCategorySectionsFromFollowUpQuestions } from "./privateHelper";

export type GenerateFollowUpQuestionsSectionPropsArgs = {
  entityId: number;
};

export const useGenerateFollowUpQuestionsSectionProps = (
  args: GenerateFollowUpQuestionsSectionPropsArgs,
): Exclude<Widget.SystemDesignTestReportProps["followUpQuestionsSection"], undefined> => {
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const currentSubmission = hooks.useCurrentSubmission();
  const lang = useLanguageCode();
  const followUpQuestionPriorityMap = useFollowUpQuestionPriorityMap();
  const systemDesignFollowUpQuestionCategoryMap = useSystemDesignFollowUpQuestionCategoryMap();

  return {
    categorySections: React.useMemo(() => {
      if (!currentSubmission) return [];

      return generateCategorySectionsFromFollowUpQuestions(
        currentSubmission.followUpQuestions,
        lang,
        followUpQuestionPriorityMap,
        systemDesignFollowUpQuestionCategoryMap,
      );
    }, [currentSubmission, lang, followUpQuestionPriorityMap, systemDesignFollowUpQuestionCategoryMap]),
  };
};
