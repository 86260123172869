import { useChallengeFollowUpQuestionCategoryMap } from "@hireroo/app-definition/challenge";
import { useFollowUpQuestionPriorityMap } from "@hireroo/app-definition/livecoding";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { generateCategorySectionsFromFollowUpQuestions } from "./privateHelper";

export type GenerateFollowUpQuestionsSectionPropsArgs = {
  challengeId: number;
};

export const useGenerateFollowUpQuestionsSectionProps = (
  args: GenerateFollowUpQuestionsSectionPropsArgs,
): Exclude<Widget.ChallengeTestReportProps["followUpQuestionsSection"], undefined> => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();
  const submissionMap = challengeHooks.useSubmissionMap();
  const submission = submissionMap[submissionId];
  const lang = useLanguageCode();
  const followUpQuestionPriorityMap = useFollowUpQuestionPriorityMap();
  const challengeFollowUpQuestionCategoryMap = useChallengeFollowUpQuestionCategoryMap();

  return {
    categorySections: React.useMemo(() => {
      if (!submission) return [];

      return generateCategorySectionsFromFollowUpQuestions(
        submission.followUpQuestions,
        lang,
        followUpQuestionPriorityMap,
        challengeFollowUpQuestionCategoryMap,
      );
    }, [challengeFollowUpQuestionCategoryMap, followUpQuestionPriorityMap, lang, submission]),
  };
};
