import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as EntityTrack from "./fields/EntityTrack";
import * as TagField from "./fields/TagsField";

export const useInheritedEntitySchema = () => {
  return z.object({
    entityId: z.number(),
    entityType: z.union([z.literal("CHALLENGE"), z.literal("SYSTEM_DESIGN")]),
    questionKey: z.string(),
  });
};
export type InheritedEntitySchema = z.infer<ReturnType<typeof useInheritedEntitySchema>>;

export const useRemoteQuestionSetupFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const entityTrack = EntityTrack.useEntityTrack();
  const inheritedEntity = useInheritedEntitySchema();

  return z.object({
    name: z
      .string()
      .min(1, { message: t("インタビュータイトルは必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("タイトル"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    candidateName: z
      .string()
      .min(1, { message: t("候補者名は必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("候補者名"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    entityTracks: entityTrack.array().max(10, { message: t("問題は10問以下で選択してください。") }),
    inheritedEntities: inheritedEntity.array().optional(),
  });
};
export type RemoteQuestionSetupFormSchema = z.infer<ReturnType<typeof useRemoteQuestionSetupFormSchema>>;

export const useRemoteReportSetupFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const viewer = AssignField.useAssignListItem();
  const tag = TagField.useTagItem();
  return z.object({
    memo: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メモ"),
        }),
      })
      .optional(),
    isPublic: z.boolean(),
    /**
     * User ID to be set when viewing privileges are changed to private.
     */
    editorUid: z.string().nullable(),
    viewers: viewer.array(),
    tags: tag.array(),
  });
};
export type RemoteReportSetupFormSchema = z.infer<ReturnType<typeof useRemoteReportSetupFormSchema>>;

export const useCreateRemoteInterview = () => {
  const remoteQuestionSetup = useRemoteQuestionSetupFormSchema();
  const remoteReportSetup = useRemoteReportSetupFormSchema();

  return z.object({
    remoteQuestionSetup,
    remoteReportSetup,
  });
};
export type CreateRemoteInterviewSchema = z.infer<ReturnType<typeof useCreateRemoteInterview>>;
