import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import CreateOptionContent, { CreateOptionContentProps } from "./parts/CreateOptionContent/CreateOptionContent";

type Step = "CHOOSE_OPTION" | "TEST_LIST";

export type RemoteCreateDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  createOption: CreateOptionContentProps;
  step: Step;
  TestList: React.ReactNode;
  testListButton: Pick<DialogWithCloseIconProps, "yesButton" | "noButton">;
};

const RemoteCreateDialog: React.FC<RemoteCreateDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    optionalDialog: {
      PaperProps: {
        sx: { minWidth: "640px", maxWidth: "840px", bgcolor: theme.palette.background.paper },
        elevation: 3,
      },
    },
    disableEnter: true,
    title: t("インタビュー作成"),
    yesButton:
      props.step === "CHOOSE_OPTION"
        ? undefined
        : {
            ...props.testListButton.yesButton,
            children: t("テストを引き継いで作成"),
            color: "secondary",
          },
    noButton:
      props.step === "CHOOSE_OPTION"
        ? undefined
        : {
            ...props.testListButton.noButton,
            children: t("戻る"),
            variant: "outlined",
            color: "outline-only",
          },
  };
  const stepMap: Record<Step, React.ReactNode> = {
    CHOOSE_OPTION: <CreateOptionContent {...props.createOption} />,
    TEST_LIST: props.TestList,
  };

  return <DialogWithCloseIcon {...dialog}>{stepMap[props.step]}</DialogWithCloseIcon>;
};

RemoteCreateDialog.displayName = "RemoteCreateDialog";

export default RemoteCreateDialog;
